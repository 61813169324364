import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import IconButton from '@mui/material/IconButton';
// components
import Iconify from 'src/components/iconify';
import { varHover } from 'src/components/animate';
import { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export default function FlagIcon({flag='us'}) {

  const popover = usePopover();

  return (
    <IconButton
      component={m.button}
      whileTap="tap"
      whileHover="hover"
      variants={varHover(1.05)}
      onClick={popover.onOpen}
      sx={{
        width: 40,
        height: 40,
      }}
    >
      {/* <Iconify icon={locales.currentLang.icon} sx={{ borderRadius: 0.65, width: 28 }} /> */}
      <Iconify icon={`flagpack:${flag}`} sx={{ borderRadius: 0.65, width: 40 }} />

    </IconButton>

  );
}
FlagIcon.propTypes = {
  flag: PropTypes.string,
};