import { useAuthContext } from 'src/auth/hooks';
import { CheckTeam } from 'src/utils/check-team';

// ----------------------------------------------------------------------

export function useTeam() {
  const { user } = useAuthContext();
  const {storedTeamObject} = CheckTeam();
  const teamObject = storedTeamObject;

  if (!teamObject?.team){
    return {};
  }   
    
  // IF LOGGED IN USER, CHECK IF THEY ARE 
  // 1) A CONFIRMED MEMBER OF THIS TEAM
  // 2) AN ADMIN
  if (user){
    // console.log('user.teams', user.teams);
    let isConfirmedTeam = false;
    let isAdmin = false;
    let ishasTeam = false;
    let role = 'Fan';
    user?.teams?.forEach((obj) => {
      if (obj.team === teamObject.team){
        ishasTeam = true;
        if (obj.confirmed === 1){
          isConfirmedTeam = true;
        }
        if (obj.admin || obj?.role === 'Admin'){
          isAdmin = true;
        }
        if (obj.role){
          role = obj?.role;
        }
      }
    });
    teamObject.MemberConfirmed = isConfirmedTeam;
    teamObject.MemberAdmin = isAdmin;
    teamObject.MemberRole = role;
    teamObject.MemberHasTeam = ishasTeam;
  } else {
    teamObject.MemberConfirmed = false;
    teamObject.MemberAdmin = false;
    teamObject.MemberRole = 'Fan';
    teamObject.MemberHasTeam = false;
  }
  
  return teamObject;
}


