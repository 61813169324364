import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';
import { useTeam } from 'src/hooks/use-team';
import { BALLCHARTS_FILE_FOLDER } from 'src/config-global';
import { paths } from 'src/routes/paths';
// utils
import { isNumeric } from 'src/utils/format-number';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const team = useTeam();

  // OR using local (public folder)
  // -------------------------------------------------------

  // console.log('team in logo', team);
  let teamLetter = '';
  if (team?.team){
    teamLetter = team.team.charAt(0).toUpperCase();
    if (isNumeric(teamLetter)){
      teamLetter = '123';
    }
  }

  const logo = team.logo && (
        <Box
          component="img"
          src={`${BALLCHARTS_FILE_FOLDER}${teamLetter}/${team.team}/${team.logo}`}
          sx={{ width: {
            xs: 60,
            md: 100,
            }, 
            cursor: 'pointer', 
            ...sx }}
        />

  );
  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href={paths.dashboard.home} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
