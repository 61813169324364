import { useState, useEffect } from 'react';
// i18n
import 'src/locales/i18n';

// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

// routes
import Router from 'src/routes/sections';

// theme
import ThemeProvider from 'src/theme';
// locales
import { LocalizationProvider } from 'src/locales';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
// sections
import { CheckoutProvider } from 'src/sections/checkout/context';
// auth
import { AuthProvider, AuthConsumer } from 'src/auth/context/ballcharts';
// import { setSession } from 'src/auth/context/ballcharts/utils';
// import { AuthProvider, AuthConsumer } from 'src/auth/context/jwt';
// import { AuthProvider, AuthConsumer } from 'src/auth/context/auth0';
// import { AuthProvider, AuthConsumer } from 'src/auth/context/amplify';
// import { AuthProvider, AuthConsumer } from 'src/auth/context/firebase';

import { CheckTeam } from 'src/utils/check-team';
import { GetTeam } from 'src/api_ballcharts/gets';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { setStorage } from 'src/hooks/use-session-storage';

import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const emptyTeam = {
  team: '',
};

export default function App() {

  const router = useRouter();
  
  const {urlTeam, teamsMatch, storedTeamObject, is404, currPath} = CheckTeam();
  // console.log('storedTeamObject in App', storedTeamObject);
  // console.log('********* urlTeam in App', urlTeam);
  const [teamObject, setTeamObject] = useState(teamsMatch ? storedTeamObject : emptyTeam);
  // console.log('********* teamsMatch in App', teamsMatch);

  const isReady = !is404 && !teamsMatch;
  // console.log('********* teamsMatch in App', teamsMatch);

  const { team, teamLoading } = GetTeam(isReady, urlTeam); 
  // console.log('********* IN App team', team);

  useEffect(() => {
    // console.log('********* IN App useEffect', team);
    if ((!is404 && !teamsMatch) && !teamLoading){
      // console.log('*********team', team);
      if (!team?.team){  // THIS TEAM DOESN'T EXIST, GO TO 404
        // console.log('*********THIS TEAM DOES not EXIST');
        router.push(paths.page404);
      } else {
        // console.log('*********setStorage', team);
        setTeamObject(team);
        setStorage('team', team);
        // window.location.replace(`/t/${team.team}/home`)
        window.location.replace(currPath)
      }
    }
  }, [is404, teamsMatch, team, router, teamLoading, currPath]);  
 
  useScrollToTop();

  if ((!is404 && !teamObject?.team) || teamLoading){
    return <LoadingScreen />
  }   

  // console.log('teamObject in App 3', teamObject);

  return (
    <AuthProvider>
      <LocalizationProvider>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'dark', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'default', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'custom', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red' | 'custom'
            themeStretch: false,
            checkUnread: false,
          }}
        >
          <ThemeProvider team={teamObject?.team}>
            <MotionLazy>
              <SnackbarProvider>
                <CheckoutProvider>
                  <SettingsDrawer />
                  <ProgressBar />
                  <AuthConsumer>
                    <Router />
                  </AuthConsumer>
                </CheckoutProvider>
              </SnackbarProvider>
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </AuthProvider>
  );
}
