import axios from 'axios';
// config
import { BALLCHARTS_HOST_API, BALLCHARTS_API_KEY } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: BALLCHARTS_HOST_API, headers: {Apikey: BALLCHARTS_API_KEY, Fromwebapp: 'web'}});

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];
  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  teams: {
    list: '/get/teams',
  },
  news: {
    list: '/get/news',
    save: '/save/new',
  },
  rosters: {
    list: '/get/rosters',
    save: '/save/roster',
  },
  schedules: {
    list: '/get/schedules',
    save: '/save/schedule',
  },
  calendars: {
    list: '/get/calendars',
    save: '/save/calendar',
  },
  stats: {
    list: '/get/stats',
    save: '/save/stats',
  },
  standings: {
    list: '/get/standings',
    save: '/save/standings',
  },
  photos: {
    list: '/get/photos',
    save: '/save/photos',
  },
  videos: {
    list: '/get/videos',
    save: '/save/videos',
  },
  links: {
    list: '/get/links',
    save: '/save/links',
  },
  contacts: {
    list: '/get/contacts',
    save: '/save/contacts',
  },
  fields: {
    list: '/get/locations',
    save: '/save/locations',
  },
  handouts: {
    list: '/get/handouts',
    save: '/save/handouts',
  },
  sponsors: {
    list: '/get/sponsors',
    save: '/save/sponsors',
  },
  registrations: {
    list: '/get/registrations',
    save: '/save/registrations',
  },
  customs: {
    list: '/get/customs',
    save: '/save/custom',
  },
  divisions: {
    list: '/get/divisions',
    save: '/save/divisions',
  },
  buttons: {
    list: '/get/buttons',
    save: '/save/buttons',
  },
  updates: {
    list: '/get/updates',
  },
  tasks: {
    list: '/get/tasks',
  },
  emails: {
    list: '/get/emails',
  },
  messages: {
    list: '/get/messages',
    save: '/save/message',
  },
  chats: {
    list: '/get/chats',
    save: '/save/chat',
    read: '/save/chatread',
  },  
  unread: {
    list: '/get/unread',
    save: '/save/unread',
  },
  members: {
    list: '/get/members',
    member_jwt: '/get/member_jwt',
    member: '/get/member',
    checkAlias: '/get/check_alias',
    register: '/save/register',
    login: '/save/login',
    logout: '/save/logout',
    verify: '/save/verify',
    resendCodeRegister: '/save/resend_code',
    forgotPassword: '/save/forgot_password',
    newPassword: '/save/new_password',
    confirm: '/save/member_confirm',
    save: '/save/member',
  },
  widgets: {
    list: '/get/widgets',
    save: '/save/widgets',
  },
  media: {
    save: '/save/media',
  },

};
