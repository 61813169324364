// ----------------------------------------------------------------------

export default function useGetNavItem({ currentUserId, conversation }) {
  const { messages, participants, teamChat, type } = conversation;

  const participantsInConversation = participants.filter(
    (participant) => participant.id !== currentUserId
  );

  const lastMessage = messages[messages.length - 1] || '';

  const group = participantsInConversation.length > 1;

  const displayName = participantsInConversation.map((participant) => participant.name).join(', ');

  const hasOnlineInGroup = group
    ? participantsInConversation.map((item) => item.status).includes('online')
    : false;

  let displayText = '';

  if (lastMessage) {
    let sender = lastMessage.senderId === currentUserId ? 'You: ' : '';
    if (type === 'GROUP'){
      const senderArray = lastMessage.senderName.split(" ");
      const senderFirst = senderArray[0];
      sender = `${senderFirst}: `;
    }

    const message = lastMessage.contentType === 'image' ? 'Sent a photo' : lastMessage.body;

    displayText = `${sender}${message}`;
  }

  return {
    group,
    displayName,
    displayText,
    participants: participantsInConversation,
    lastActivity: lastMessage?.createdAt || new Date(),
    hasOnlineInGroup,
    teamChat
  };
}
