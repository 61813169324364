import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axiosInstance, { endpoints } from 'src/utils/axios_ballcharts';
//
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
  errorMessage: '',
};

const reducer = (state, action) => {
  // console.log('state, action', state, action);
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
      errorMessage: action.payload.errorMessage,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {

  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY);
     // console.log('accessToken', accessToken)
      if (accessToken && isValidToken(accessToken)) {
        // setSession(accessToken);

        const headers = {
          'Authentication': accessToken,
        };        

        const response = await axiosInstance.get(endpoints.members.member_jwt, { headers });
        // console.log('members.member_jwt response.data', response.data);

        const { user, newToken, error } = response.data;

        if (error){
          setSession(null);
          dispatch({
            type: 'INITIAL',
            payload: {
              user: null,
              errorMessage: error,
            },
          });
        } else {
          setSession(newToken);

          dispatch({
            type: 'INITIAL',
            payload: {
              user: {
                ...user,
                accessToken: newToken,
                errorMessage: '',
              },
            },
          });
        }

      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []); 

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (team, email, password) => {
    const data = {
      team,
      email,
      password,
    };

    // console.log('GETTING TO BALLCHARTS LOGIN', data);

    const response = await axiosInstance.post(endpoints.members.login, data);

    // console.log('members.login response.data', response.data);
    const { accessToken, user, error } = response.data;

    const successfullLogin = {loggedIn: false, error: ''};

    // console.log('members.login status', status);
    // console.log('members.login error', error);

    if (error){
      successfullLogin.error = error;
      // console.log('login error', error);
      dispatch({
        type: 'LOGIN',
        payload: {
          user: null,
          errorMessage: error,
        },
      });

    } else {
      setSession(accessToken);
      successfullLogin.loggedIn = true;
      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            ...user,
            accessToken,
            errorMessage: '',

          },
        },
      });
    }
    return successfullLogin;
  }, []);

  // REGISTER
  const register = useCallback(async (team, email, password, name, username) => {
    const data = {
      team,
      email,
      password,
      name,
      username,
    };

    const response = await axiosInstance.post(endpoints.members.register, data);

    const { accessToken, user, error } = response.data;

    localStorage.setItem(STORAGE_KEY, accessToken);

    const successfullRegister = {loggedIn: false, error: ''};

    if (error){
      successfullRegister.error = error;
      // console.log('login error', error);
      dispatch({
        type: 'REGISTER',
        payload: {
          user: null,
          errorMessage: error,
        },
      });

    } else {
      setSession(accessToken);
      successfullRegister.loggedIn = true;
      dispatch({
        type: 'REGISTER',
        payload: {
          user: {
            ...user,
            accessToken,
            errorMessage: '',
          },
        },
      });
    }
    return successfullRegister;

  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    // ALSO NEED TO EMPTY SESSION HERE
    await axiosInstance.post(endpoints.members.logout);
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);


  // CONFIRM REGISTER
  const confirmRegister = useCallback(async (team, email, code) => {
    await axiosInstance.post(endpoints.members.verify, {team, email, code });
    // await Auth.confirmSignUp(email, code);
  }, []);

  // RESEND CODE REGISTER
  const resendCodeRegister = useCallback(async (team, email) => {
    await axiosInstance.post(endpoints.members.resendCodeRegister, {team, email });
    // await Auth.resendSignUp(email);
  }, []);

  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (team, email) => {
    const response = await axiosInstance.post(endpoints.members.forgotPassword, {team, email});
    // console.log('response in forgotPassword', response);
    // await Auth.forgotPassword(email);
    return response.data;
  }, []);

  // NEW PASSWORD
  const newPassword = useCallback(async (team, email, code, password, confirmPassword) => {
    const response = await axiosInstance.post(endpoints.members.newPassword, {team, email, code, password, confirmPassword });
    // console.log('response in newPassword', response);
    // await Auth.forgotPasswordSubmit(email, code, password);
    return response.data;
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  // console.log('status is ', status);

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'ballcharts',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      errorMessage: state.errorMessage,
      //
      login,
      logout,
      register,
      newPassword,
      forgotPassword,
      confirmRegister,
      resendCodeRegister,
    }),
    [login, logout, register, state, status, newPassword,forgotPassword,confirmRegister,resendCodeRegister ]
  );
  // console.log('memoizedValue in AuthProvider', memoizedValue);

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
