// utils
import { paramCase } from 'src/utils/change-case';
import { _id, _postTitles } from 'src/_mock/assets';
import { getStorage } from 'src/hooks/use-session-storage';
// import { useTeam } from 'src/hooks/use-team';

// ----------------------------------------------------------------------
const teamObject = getStorage('team');
const teamHere = teamObject?.team;

// console.log('teamHere', teamHere);

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: `/${teamHere}`,
};

// ----------------------------------------------------------------------

export const paths = {

  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/hjxMnGUJCjY7pX8lQbS7kn/%5BPreview%5D-Minimal-Web.v5.4.0?type=design&node-id=0-1&mode=design&t=2fxnS70DuiTLGzND-0',
  ballcharts: 'https://ballcharts.com/site/',
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  post: {
    root: `/post`,
    details: (title) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // AUTH
  auth: {
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      register: `${ROOTS.AUTH}/amplify/register`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
    },
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
    ballcharts: {
      login: `${ROOTS.AUTH}/ballcharts/login`,
      loginteam: `${ROOTS.AUTH}/ballcharts/login?team=${teamHere}`,
      register: `${ROOTS.AUTH}/ballcharts/register`,
      registerteam: `${ROOTS.AUTH}/ballcharts/register?team=${teamHere}`,
      verify: `${ROOTS.AUTH}/ballcharts/verify`,
      verifyteam: `${ROOTS.AUTH}/ballcharts/verify?team=${teamHere}`,
      newPassword: `${ROOTS.AUTH}/ballcharts/new-password`,
      newPasswordTeam: `${ROOTS.AUTH}/ballcharts/new-password?team=${teamHere}`,
      forgotPassword: `${ROOTS.AUTH}/ballcharts/forgot-password`,
      forgotPasswordTeam: `${ROOTS.AUTH}/ballcharts/forgot-password?team=${teamHere}`,
    },    
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
    },
  },
  authDemo: {
    classic: {
      login: `${ROOTS.AUTH_DEMO}/classic/login`,
      register: `${ROOTS.AUTH_DEMO}/classic/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
    },
    modern: {
      login: `${ROOTS.AUTH_DEMO}/modern/login`,
      register: `${ROOTS.AUTH_DEMO}/modern/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
    },
  },
  // dashboard
  dashboard: {
    root: `${ROOTS.DASHBOARD}/home`,
    // BALLCHARTS
    home: `${ROOTS.DASHBOARD}/home`,
    news: {
      root: `${ROOTS.DASHBOARD}/news`,
      new: (id) => `${ROOTS.DASHBOARD}/news/${id}`,
    },

    divisions: `${ROOTS.DASHBOARD}/divisions`,
    rosters: {
      root: `${ROOTS.DASHBOARD}/rosters`,
      player: (id) => `${ROOTS.DASHBOARD}/rosters/${id}`,
    },
    schedules: {
      root: `${ROOTS.DASHBOARD}/schedules`,
      game: (id) => `${ROOTS.DASHBOARD}/schedules/${id}`,
    },    
    calendars: `${ROOTS.DASHBOARD}/calendars`,
    standings: `${ROOTS.DASHBOARD}/standings`,
    stats: `${ROOTS.DASHBOARD}/stats`,
    photos: `${ROOTS.DASHBOARD}/photos`,
    videos: `${ROOTS.DASHBOARD}/videos`,
    links: `${ROOTS.DASHBOARD}/links`,
    contacts: `${ROOTS.DASHBOARD}/contacts`,
    fields: `${ROOTS.DASHBOARD}/fields`,
    handouts: `${ROOTS.DASHBOARD}/handouts`,
    sponsors: `${ROOTS.DASHBOARD}/sponsors`,
    registrations: `${ROOTS.DASHBOARD}/registrations`,
    customs: (id) => `${ROOTS.DASHBOARD}/customs/${id}`,
    chats: {
      root: `${ROOTS.DASHBOARD}/chats`,
      chat: (id) => `${ROOTS.DASHBOARD}/chats/${id}`,
    },

    comments: `${ROOTS.DASHBOARD}/comments`,
    emails: {
      root: `${ROOTS.DASHBOARD}/emails`,
      email: (id) => `${ROOTS.DASHBOARD}/emails/${id}`,
    },    

    updates: `${ROOTS.DASHBOARD}/updates`,
    member: {
      account: `${ROOTS.DASHBOARD}/member/account`, // logged in member
      root: `${ROOTS.DASHBOARD}/member/profile`, // logged in member
      profile: (id) => `${ROOTS.DASHBOARD}/member/${id}`, // another member, can do later
    },

    admin: {
      root: `${ROOTS.DASHBOARD}/admin/menu`,
      members: `${ROOTS.DASHBOARD}/admin/members`,
      member: {
        new: `${ROOTS.DASHBOARD}/admin/member/new`,
        edit: (id) => `${ROOTS.DASHBOARD}/admin/member/${id}`, // another member, can do later
      },      
    },

    // END OF BALLCHARTS

    mail: `${ROOTS.DASHBOARD}/mail`,
    chat: `${ROOTS.DASHBOARD}/chat`,
    blank: `${ROOTS.DASHBOARD}/blank`,
    kanban: `${ROOTS.DASHBOARD}/kanban`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    fileManager: `${ROOTS.DASHBOARD}/file-manager`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking`,
      booking: `${ROOTS.DASHBOARD}/booking`,
      file: `${ROOTS.DASHBOARD}/file`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },
    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      new: `${ROOTS.DASHBOARD}/product/new`,
      details: (id) => `${ROOTS.DASHBOARD}/product/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
      },
    },
    invoice: {
      root: `${ROOTS.DASHBOARD}/invoice`,
      new: `${ROOTS.DASHBOARD}/invoice/new`,
      details: (id) => `${ROOTS.DASHBOARD}/invoice/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}/edit`,
      },
    },
    post: {
      root: `${ROOTS.DASHBOARD}/post`,
      new: `${ROOTS.DASHBOARD}/post/new`,
      details: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
      edit: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
        edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
      },
    },
    order: {
      root: `${ROOTS.DASHBOARD}/order`,
      details: (id) => `${ROOTS.DASHBOARD}/order/${id}`,
      demo: {
        details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
      },
    },
    job: {
      root: `${ROOTS.DASHBOARD}/job`,
      new: `${ROOTS.DASHBOARD}/job/new`,
      details: (id) => `${ROOTS.DASHBOARD}/job/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/job/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/job/${MOCK_ID}/edit`,
      },
    },
    tour: {
      root: `${ROOTS.DASHBOARD}/tour`,
      new: `${ROOTS.DASHBOARD}/tour/new`,
      details: (id) => `${ROOTS.DASHBOARD}/tour/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
      test: (team, id) => `${ROOTS.DASHBOARD}/tour/${teamHere}/${id}`,
      test2: `${ROOTS.DASHBOARD}/tour/new`,
      demo: {
        details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
        test: `${ROOTS.DASHBOARD}/tour/sampleteam/${MOCK_ID}`,
      },
    },
  },
};
