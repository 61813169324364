import PropTypes from 'prop-types';
import React, { useEffect, useState }  from 'react';
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

export default function GoogleAd({ slot, type='horizontal'}) {
  const [googleLoaded, setGoogleLoaded] = useState(false);
  const pathArray = window.location.pathname.split('/');
  const page = pathArray[3];

  // console.log('page', page);

  useEffect(() => {
    const script = document.createElement('script');
  
    script.src = "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3107117788286492";
    script.async = true;
    script.addEventListener('load', ()=>setGoogleLoaded(true));
    document.body.appendChild(script);  
  }, []);

  useEffect(() => {
    const pushAd = () => {
      // console.log('window', window);
      try {
        const { adsbygoogle } = window;
        // console.log({ adsbygoogle })
        adsbygoogle.push({})
      } catch (e) {
        console.error(e)
      }
    }
    if (googleLoaded){
      pushAd(); 
    }
    /*
    const interval = setInterval(() => {
      // Check if Adsense script is loaded every 300ms
      if (window.adsbygoogle) {
        pushAd()
        // clear the interval once the ad is pushed so that function isn't called indefinitely
        clearInterval(interval)
      }
    }, 300)

    return () => {
      clearInterval(interval)
    }
    */
  }, [page, googleLoaded]);

  if (!googleLoaded){
    return <LoadingScreen />
  }   

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block", width: 728, height:90}}
      data-ad-client="ca-pub-3107117788286492"
      data-ad-slot={slot}
      data-adtest="on"
      key={`googlead_${page}_${slot}`}
      // data-ad-format="auto"
      // data-full-width-responsive="true"
    />
  )  
}
GoogleAd.propTypes = {
  slot: PropTypes.string,
  type: PropTypes.string,
};