import { m } from 'framer-motion';
import { useState, useCallback, useEffect } from 'react';
// @mui
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
// _mock
import { _notifications } from 'src/_mock';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
//
import { useAuthContext } from 'src/auth/hooks';
import { useSettingsContext } from 'src/components/settings';
import { useTeam } from 'src/hooks/use-team';
import { GetUnread, GetEmails, GetComments, GetUpdates, GetChats } from 'src/api_ballcharts/gets';

import axiosInstance, { endpoints } from 'src/utils/axios_ballcharts';

import NotificationItem from './notification-item';
import ChatNotificationItem from './chat-notification-item';
// ----------------------------------------------------------------------

const TABS = [
  /*
  {
    value: 'tasks',
    label: 'Tasks',
    count: 22,
    color: 'default'
  },
  {
    value: 'chats',
    label: 'Chats',
    count: 0,
    color: 'success'
  },
  */
  {
    value: 'comments',
    label: 'Comments',
    count: 0,
    color: 'error'
  },  {
    value: 'emails',
    label: 'Emails',
    count: 0,
    color: 'info'
  },

  {
    value: 'updates',
    label: 'Updates',
    count: 0,
    color: 'default'
  },
];

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const drawer = useBoolean();
  const router = useRouter();
  const team = useTeam();
  const settings = useSettingsContext();
  const { user } = useAuthContext();

  const smUp = useResponsive('up', 'sm');

  const TIMETOCHECKNOTIFICATIONS = 10000;  // 1000 = 1 sec, 60000 = 1 minute

  useEffect(() => {
    const interval = setInterval(() => {
      // console.log('Logs every minute');
      // SET TO RESET UNREAD
      settings.onChangecheckUnread(true);      
    }, TIMETOCHECKNOTIFICATIONS);
  
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [settings])  

  const [currentTab, setCurrentTab] = useState('comments');

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);
  
  // eslint-disable-next-line
  const [notifications, setNotifications] = useState(_notifications);
  const [emails, setEmails] = useState([]);
  // eslint-disable-next-line
  const [comments, setComments] = useState([]);
  // eslint-disable-next-line
  const [updates, setUpdates] = useState([]);
  const [chats, setChats] = useState([]);
  const [emailsLoaded, setEmailsLoaded] = useState(false);
  const [commentsLoaded, setCommentsLoaded] = useState(false);
  const [updatesLoaded, setUpdatesLoaded] = useState(false);
  const [chatsLoaded, setChatsLoaded] = useState(false);
  const [unreads, setUnreads] = useState([]);
  const [unreadsLoaded, setUnreadsLoaded] = useState(false);
  const [loadCount, setLoadCount] = useState({emails:0,comments:0,updates:0,chats:0,unread:0});

  // console.log('loadCount', loadCount);
  // console.log('settings.checkUnread in Notifications', settings.checkUnread);

  useEffect(() => {
    if (settings.checkUnread === true){
      setUnreadsLoaded(false);
      setEmailsLoaded(false);
      setCommentsLoaded(false);
      setUpdatesLoaded(false);
      setChatsLoaded(false);
      // setComments([]);
      // setEmails([]);
      // setUpdates([]);
      // setChats([]);
      setLoadCount({...loadCount, emails: loadCount.emails+1});
      setLoadCount({...loadCount, comments: loadCount.comments+1});
      setLoadCount({...loadCount, updates: loadCount.updates+1});
      setLoadCount({...loadCount, chats: loadCount.chats+1});
      setLoadCount({...loadCount, unread: loadCount.unread+1});
      // setCurrentTab('updates');
      settings.onChangecheckUnread(false);
      // console.log('**************SHOULD BE GetUnread AGAIN');
    }
  }, [settings, loadCount]);  

  const { unreadArray, unreadLoading } = GetUnread(!unreadsLoaded, team.team, user.id, '', loadCount.unread);
  
  // console.log('unreadArray', unreadArray);

  if (!unreadLoading && unreadArray !== undefined){
    setUnreadsLoaded(true);
    setUnreads(unreadArray);
    setLoadCount({...loadCount, unread: loadCount.unread+1});
  }

  // const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;
  const totalUnRead = unreads.Total;
  // TABS[0].count = unreads.Chats;
  TABS[0].count = unreads.Messages;
  TABS[1].count = unreads.Emails;
  TABS[2].count = unreads.Updates;

  const limit = 20;

  const { commentsArray, commentsLoading } = GetComments(drawer.value && !commentsLoaded && currentTab === 'comments', team.team, user.id, '', '', 0, limit, '','','','', loadCount.comments);
  
  // console.log('drawer', drawer);

  if (!commentsLoading && commentsArray !== undefined){
    setCommentsLoaded(true);
    setComments(commentsArray);
    setLoadCount({...loadCount, comments: loadCount.comments+1});

  }  

  const { emailsArray, emailsLoading } = GetEmails(drawer.value && !emailsLoaded && currentTab === 'emails', team.team, user.id, 0, 0, limit, loadCount.emails);
  
  // console.log('emailsArray', emailsArray);

  if (!emailsLoading && emailsArray !== undefined){
    setEmailsLoaded(true);
    setEmails(emailsArray);
    setLoadCount({...loadCount, emails: loadCount.emails+1});

  }

  // console.log('user', user);

  const { updatesArray, updatesLoading } = GetUpdates(drawer.value && !updatesLoaded && currentTab === 'updates', team.team, user.id, 0, limit, loadCount.updates);
  // console.log('updatesArray', updatesArray);

  if (!updatesLoading && updatesArray !== undefined){
    setUpdatesLoaded(true);
    setUpdates(updatesArray);
    setLoadCount({...loadCount, updates: loadCount.updates+1});

  }  

  const { conversationArray, conversationsLoading } = GetChats(drawer.value && !chatsLoaded && currentTab === 'chats', team.team, user.id, loadCount.chats);
  // console.log('updatesArray', updatesArray);

  if (!conversationsLoading && conversationArray !== undefined){
    setChatsLoaded(true);
    setChats(conversationArray);
    setLoadCount({...loadCount, chats: loadCount.chats+1});
  }    

  // console.log('updates', updates);
  /*
  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };
  */

  const handleMarkAllAsRead = useCallback(() => {
      // SAVE UNREAD FOR THIS MEMBER
      const eventData = {
        team: team.team,
        member: user.id,
        type: 'all'
      };
      axiosInstance.post(endpoints.unread.save, eventData).then((response) => {
        // console.log('response.data', response.data);
        // SET TO RESET UNREAD
        settings.onChangecheckUnread(true);
      });      

  }, [team, user, settings]);  

  const handleOpenNotifications = useCallback(() => {
    settings.onChangecheckUnread(true);
    drawer.onTrue();
  }, [drawer, settings]);  


  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Notifications
      </Typography>

      {!!totalUnRead && (
        <Tooltip title="Mark all as read">
          <IconButton color="primary" onClick={handleMarkAllAsRead}>
            <Iconify icon="eva:done-all-fill" />
          </IconButton>
        </Tooltip>
      )}

      {!smUp && (
        <IconButton onClick={drawer.onFalse}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      )}
    </Stack>
  );

  const renderTabs = (
    <Tabs value={currentTab} onChange={handleChangeTab}>
      {TABS.map((tab) => (
        <Tab
          key={tab.value}
          iconPosition="end"
          value={tab.value}
          label={tab.label}
          icon={
            tab.count > 0 ? (
              <Label
                variant={((tab.value === currentTab) && 'filled') || 'soft'}
                color={tab.color}
              >
                {tab.count}
              </Label>
            ) : ('')
          }
          sx={{
            '&:not(:last-of-type)': {
              mr: 3,
            },
          }}
        />
      ))}
    </Tabs>
  );

  const renderListTasks = (
    <Scrollbar>
      <List disablePadding>
        {notifications.map((notification) => (
          <NotificationItem key={notification.id} notification={notification} />
        ))}
      </List>
    </Scrollbar>
  );
  
  const renderListChats = (
    <Scrollbar>
      <List disablePadding>
      {loadCount.chats === 0 && conversationsLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
          <CircularProgress />
        </Box>
      ):(
        chats.map((chat) => (
          <ChatNotificationItem key={chat.id} conversation={chat} />
        ))
      )}
      </List>
    </Scrollbar>
  );   

  const renderListComments = (
    <Scrollbar>
      <List disablePadding>
      {loadCount.comments === 0 && commentsLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
          <CircularProgress />
        </Box>
      ):(
        comments.map((comment) => (
          <NotificationItem key={comment.id} notification={comment} />
        ))
      )}
      </List>
    </Scrollbar>
  );    

  const renderListEmails = (
    <Scrollbar>
      <List disablePadding>
        {loadCount.emails === 0 && emailsLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
            <CircularProgress />
          </Box>
        ):(
          emails?.map((email) => (
            <NotificationItem key={email.id} notification={email} />
          ))
        )}
      </List>
    </Scrollbar>
  );  

  const renderListUpdates = (
    <Scrollbar>
      <List disablePadding>
        {loadCount.updates === 0 && updatesLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
          <CircularProgress />
        </Box>
      ):(
        updates?.map((update) => (
          <NotificationItem key={update.id} notification={update} />
        ))
      )}
      </List>
    </Scrollbar>
  );     

  // const currentTabTeam = `${currentTab}team`;

  let allPath = paths.dashboard[currentTab];
  if (currentTab === 'chats'){
    allPath = paths.dashboard.chats.root;
  } else if (currentTab === 'emails'){
    allPath = paths.dashboard.emails.root;

  } 

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={handleOpenNotifications}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}

        <Divider />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pl: 2.5, pr: 1 }}
        >
          {renderTabs}
        </Stack>

        <Divider />

        {currentTab === 'chats' && renderListChats}
        {currentTab === 'comments' && renderListComments}
        {currentTab === 'emails' && renderListEmails}
        {currentTab === 'updates' && renderListUpdates}
        {currentTab === 'tasks' && renderListTasks}

        <Box sx={{ p: 1 }}>
          <Button
            fullWidth
            size="large"
            onClick={() => {
              drawer.onFalse();
              router.push(allPath);
            }}          
          >
            View All
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
