import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
import CompactLayout from 'src/layouts/compact';
import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// BALLCHARTS
const BallChartsLoginPage = lazy(() => import('src/pages/auth/ballcharts/login'));
const BallChartsRegisterPage = lazy(() => import('src/pages/auth/ballcharts/register'));
const BallChartsVerifyPage = lazy(() => import('src/pages/auth/ballcharts/verify'));
const BallChartsNewPasswordPage = lazy(() => import('src/pages/auth/ballcharts/new-password'));
const BallChartsForgotPasswordPage = lazy(() => import('src/pages/auth/ballcharts/forgot-password'));
// ----------------------------------------------------------------------

const authBallCharts = {
  path: 'ballcharts',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthModernCompactLayout>
          <BallChartsLoginPage title='Hi, Welcome'/>
        </AuthModernCompactLayout>
      ),
    },
    {
      path: 'register',
      element: (
        <AuthModernCompactLayout title="Manage the job more effectively with Minimal">
          <BallChartsRegisterPage />
        </AuthModernCompactLayout>
      ),
    },
    {
      element: (
        <CompactLayout>
          <Outlet />
        </CompactLayout>
      ),
      children: [
        { path: 'verify', element: <BallChartsVerifyPage /> },
        { path: 'new-password', element: <BallChartsNewPasswordPage /> },
        { path: 'forgot-password', element: <BallChartsForgotPasswordPage /> },
      ],
    },

  ],
};

export const authRoutes = [
  {
    path: 'auth',
    children: [authBallCharts],
  },
];
