import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { formatDistanceToNowStrict } from 'date-fns';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import AvatarGroup from '@mui/material/AvatarGroup';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
// hooks
import { useAuthContext } from 'src/auth/hooks';
// api
// import { clickConversation } from 'src/api/chat';
import { clickConversation } from 'src/api_ballcharts/gets';
import { BALLCHARTS_FILE_FOLDER } from 'src/config-global';
//
import { useTeam } from 'src/hooks/use-team';
import { useGetNavItem } from 'src/components/ballcharts/chats/hooks';
import { useSettingsContext } from 'src/components/settings';

// ----------------------------------------------------------------------

export default function ChatNotificationItem({ conversation }) {
  const { user } = useAuthContext();
  const team = useTeam();
  let teamname1 = '';
  if (team?.teamname){
    const teamnameArray = team?.teamname?.split('|');
    teamname1 = teamnameArray[0] || '';
  }

  let teamLetter = '';
  if (team?.team){
    teamLetter = team.team.charAt(0).toUpperCase();
  }
  const logo = `${BALLCHARTS_FILE_FOLDER}${teamLetter}/${team.team}/${team.logo}`;

  const settings = useSettingsContext();

  const router = useRouter();

  const { group, displayName, displayText, participants, lastActivity, hasOnlineInGroup, teamChat } =
    useGetNavItem({
      conversation,
      currentUserId: `${user?.id}`,
    });

  const singleParticipant = participants[0] || {};

  const { name, avatarUrl, status } = singleParticipant;

  const handleClickConversation = useCallback(async () => {
    try {
      // console.log('conversation in handleClickConversation', conversation);

      if (conversation.unreadCount > 0){
        conversation.unreadCount = 0;
        settings.onChangecheckUnread(true);
      }

      await clickConversation(conversation.id, team.team, user.id);

      router.push(paths.dashboard.chats.chat(conversation.id));
    } catch (error) {
      console.error(error);
    }
  }, [conversation, router, settings, team, user]);

  const renderGroup = (
    <Badge
      variant={hasOnlineInGroup ? 'online' : 'invisible'}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <AvatarGroup variant="compact" sx={{ width: 48, height: 48 }}>
        {participants.slice(0, 2).map((participant) => (
          <Avatar key={participant.id} alt={participant.name} src={participant.avatarUrl} />
        ))}
      </AvatarGroup>
    </Badge>
  );

  const renderSingle = (
    <Badge key={status} variant={status} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <Avatar alt={name} src={avatarUrl} sx={{ width: 48, height: 48 }} />
    </Badge>
  );

  const renderTeamLogo = (
    <Badge key={teamname1} variant="success" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <Avatar alt={teamname1} src={logo} sx={{ width: 48, height: 48 }} />
    </Badge>
  );  

  return (
    <ListItemButton
      disableGutters
      onClick={handleClickConversation}
      sx={{
        py: 1.5,
        px: 2.5,
      }}
    >
      <Badge
        color="error"
        overlap="circular"
        badgeContent={conversation.unreadCount}
      >
        {teamChat 
          ? renderTeamLogo
          : <>
              {group ? renderGroup : renderSingle}
            </>
        }
      </Badge>

        <>
          <ListItemText
            sx={{ ml: 2 }}
            primary={teamChat ? 'Team Chat' : displayName}
            primaryTypographyProps={{
              noWrap: true,
              variant: 'subtitle2',
            }}
            secondary={displayText}
            secondaryTypographyProps={{
              noWrap: true,
              component: 'span',
              variant: conversation.unreadCount ? 'subtitle2' : 'body2',
              color: conversation.unreadCount ? 'text.primary' : 'text.secondary',
            }}
          />

          <Stack alignItems="flex-end" sx={{ ml: 2, height: 44 }}>
            <Typography
              noWrap
              variant="body2"
              component="span"
              sx={{
                mb: 1.5,
                fontSize: 12,
                color: 'text.disabled',
              }}
            >
              {formatDistanceToNowStrict(new Date(lastActivity), {
                addSuffix: false,
              })}
            </Typography>

            {!!conversation.unreadCount && (
              <Box
                sx={{
                  width: 8,
                  height: 8,
                  bgcolor: 'info.main',
                  borderRadius: '50%',
                }}
              />
            )}
          </Stack>
        </>
    </ListItemButton>
  );
}

ChatNotificationItem.propTypes = {
  conversation: PropTypes.object,
};
