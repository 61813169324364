import PropTypes from 'prop-types';
import { useCallback } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
// utils
import { fToNow } from 'src/utils/format-time';
// components
import Label from 'src/components/label';
import FileThumbnail from 'src/components/file-thumbnail';
import Iconify from 'src/components/iconify';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { clickNotification } from 'src/api_ballcharts/gets';
import { useSettingsContext } from 'src/components/settings';

import { useAuthContext } from 'src/auth/hooks';
import { useTeam } from 'src/hooks/use-team';

// ----------------------------------------------------------------------

export default function NotificationItem({ notification }) {
  const team = useTeam();
  const { user } = useAuthContext();
  const router = useRouter();
  const settings = useSettingsContext();


  const renderAvatar = (
    <ListItemAvatar>
      {
      // eslint-disable-next-line
      notification.avatarUrl ? (
        <Avatar src={notification.avatarUrl} sx={{ bgcolor: 'background.neutral' }} />
      ) : (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            width: 40,
            height: 40,
            borderRadius: '50%',
            bgcolor: 'background.neutral',
          }}
        >
        {notification.icon ? (
          <Iconify icon={notification.icon} width={15} />
        ) : (
          <Box
            component="img"
            src={`${process.env.PUBLIC_URL}/assets/icons/notification/${
              (notification.type === 'order' && 'ic_order') ||
              (notification.type === 'chat' && 'ic_chat') ||
              (notification.type === 'comment' && 'ic_chat') ||
              (notification.type === 'mail' && 'ic_mail') ||
              (notification.type === 'email' && 'ic_mail') ||
              (notification.type === 'delivery' && 'ic_delivery')
            }.svg`}
            sx={{ width: 24, height: 24 }}
          />
        )}
        </Stack>
      )
      }
    </ListItemAvatar>
  );

  const renderText = (
    <ListItemText
      disableTypography
      primary={reader(notification.title)}
      secondary={
        <>
          <Stack
            direction="row"
            alignItems="center"
            sx={{ typography: 'caption', color: 'text.disabled' }}
            divider={
              <Box
                sx={{
                  width: 2,
                  height: 2,
                  bgcolor: 'currentColor',
                  mx: 0.5,
                  borderRadius: '50%',
                }}
              />
            }
          >
            {fToNow(notification.createdAt)}
            {notification.category}
          </Stack>
          {notification.type === 'chat' && (
            <Stack alignItems="flex-start">
              <Box
                sx={{
                  p: 1.5,
                  my: 1.5,
                  borderRadius: 1.5,
                  color: 'text.secondary',
                  bgcolor: 'background.neutral',
                }}
              >
                {reader(notification.description)}
              </Box>
            </Stack>
          )}
          {notification.type === 'comment' && (
            <Stack alignItems="flex-start">
              <Box sx={{fontSize: '0.8em'}}>
                {reader(notification.description.length > 60 ? 
                  `${notification.description.substring(0, 60)}...` 
                  : 
                  notification.description
                )}
              </Box>
            </Stack>
          )}
        </>
      }
    />
  );

  const renderUnReadBadge = notification.isUnRead && (
    <Box
      sx={{
        top: 26,
        width: 8,
        height: 8,
        right: 20,
        borderRadius: '50%',
        bgcolor: 'info.main',
        position: 'absolute',
      }}
    />
  );

  const friendAction = (
    <Stack spacing={1} direction="row" sx={{ mt: 1.5 }}>
      <Button size="small" variant="contained">
        Accept
      </Button>
      <Button size="small" variant="outlined">
        Decline
      </Button>
    </Stack>
  );

  const projectAction = (
    <Stack alignItems="flex-start">
      <Box
        sx={{
          p: 1.5,
          my: 1.5,
          borderRadius: 1.5,
          color: 'text.secondary',
          bgcolor: 'background.neutral',
        }}
      >
        {reader(
          `<p><strong>@Jaydon Frankie</strong> feedback by asking questions or just leave a note of appreciation.</p>`
        )}
      </Box>

      <Button size="small" variant="contained">
        Reply
      </Button>
    </Stack>
  );

  const fileAction = (
    <Stack
      spacing={1}
      direction="row"
      sx={{
        pl: 1,
        p: 1.5,
        mt: 1.5,
        borderRadius: 1.5,
        bgcolor: 'background.neutral',
      }}
    >
      <FileThumbnail
        file="http://localhost:8080/httpsdesign-suriname-2015.mp3"
        sx={{ width: 40, height: 40 }}
      />

      <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }} flexGrow={1} sx={{ minWidth: 0 }}>
        <ListItemText
          disableTypography
          primary={
            <Typography variant="subtitle2" component="div" sx={{ color: 'text.secondary' }} noWrap>
              design-suriname-2015.mp3
            </Typography>
          }
          secondary={
            <Stack
              direction="row"
              alignItems="center"
              sx={{ typography: 'caption', color: 'text.disabled' }}
              divider={
                <Box
                  sx={{
                    mx: 0.5,
                    width: 2,
                    height: 2,
                    borderRadius: '50%',
                    bgcolor: 'currentColor',
                  }}
                />
              }
            >
              <span>2.3 GB</span>
              <span>30 min ago</span>
            </Stack>
          }
        />

        <Button size="small" variant="outlined">
          Download
        </Button>
      </Stack>
    </Stack>
  );

  const tagsAction = (
    <Stack direction="row" spacing={0.75} flexWrap="wrap" sx={{ mt: 1.5 }}>
      <Label variant="outlined" color="info">
        Design
      </Label>
      <Label variant="outlined" color="warning">
        Dashboard
      </Label>
      <Label variant="outlined">Design system</Label>
    </Stack>
  );

  const paymentAction = (
    <Stack direction="row" spacing={1} sx={{ mt: 1.5 }}>
      <Button size="small" variant="contained">
        Pay
      </Button>
      <Button size="small" variant="outlined">
        Decline
      </Button>
    </Stack>
  );


  // console.log('notification', notification);
  // console.log('notification.linkPage', notification.linkPage);
  // console.log('paths.dashboard[notification.linkPage]', paths.dashboard[notification.linkPage]);
  // console.log('notification.linkPage', notification.linkPage);
  // console.log('notification.linkId', notification.linkId);
  // console.log('paths.dashboard.customs(notification.linkId)', paths.dashboard.customs(notification.linkId));

  let linkPage = paths.dashboard[notification.linkPage];
  if (notification.linkPage === 'custom'){
    linkPage = paths.dashboard.customs(notification.linkId);
  } else if (notification.linkPage === 'news'){
    linkPage = paths.dashboard.news.new(notification.linkId)
  } else if (notification.linkPage === 'player'){
    linkPage = paths.dashboard.rosters.player(notification.linkId)
  } else if (notification.linkPage === 'game'){
    linkPage = paths.dashboard.schedules.game(notification.linkId)
  } else if (notification.linkPage === 'email'){
    linkPage = paths.dashboard.emails.email(notification.linkId)
  }
  const handleClickNotification = useCallback(async () => {
    try {
      router.push(linkPage);

      if (notification.isUnRead ===  true){
        notification.isUnRead = false;
        settings.onChangecheckUnread(true);
        await clickNotification(notification, team.team, user.id);
      }

    } catch (error) {
      console.error(error);
    }
  }, [notification, linkPage, router, team, user, settings]);



  return (
    <ListItemButton
      disableRipple
      sx={{
        p: 2.5,
        alignItems: 'flex-start',
        borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
      }}
      onClick={handleClickNotification}
    >
      {renderUnReadBadge}

      {renderAvatar}

      <Stack sx={{ flexGrow: 1 }}>
        {renderText}
        {notification.type === 'friend' && friendAction}
        {notification.type === 'project' && projectAction}
        {notification.type === 'file' && fileAction}
        {notification.type === 'tags' && tagsAction}
        {notification.type === 'payment' && paymentAction}
      </Stack>
    </ListItemButton>
  );
}

NotificationItem.propTypes = {
  notification: PropTypes.object,
};

// ----------------------------------------------------------------------

function reader(data) {
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: data }}
      sx={{
        mb: 0.5,
        '& p': { typography: 'body2', m: 0 },
        '& a': { color: 'inherit', textDecoration: 'none' },
        '& strong': { typography: 'subtitle2' },
      }}
    />
  );
}
