import useSWR, { mutate }  from 'swr';
import { useMemo } from 'react';
// utils
import axiosInstance, { fetcher, endpoints } from 'src/utils/axios_ballcharts';
import keyBy from 'lodash/keyBy';

// ----------------------------------------------------------------------

export function GetTeam(isReady, team) {
  // console.log('team in GetTeam', team);

  // const rand = Math.random() * 10; // FORCE GET TEAM

  const URL = (isReady && team) ? [endpoints.teams.list, { params: { team } }] : null;
  // console.log('*********** URL in GetTeam', URL);

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  // console.log('error in GetTeam', error);
  // console.log('data in GetTeam', data);

  const memoizedValue = useMemo(
    () => ({
      team: data,
      teamLoading: isLoading,
      teamError: error,
      teamValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );
  // console.log('memoizedValue in GetTeam', memoizedValue);

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function GetNews(isReady, team, id='', query='', from=0, limit=12) {
  const URL = (isReady && team) ? [endpoints.news.list, { params: { team, id, query, from, limit } }] : null;
  // console.log('URL', URL);

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  // console.log('data', data);

  const memoizedValue = useMemo(
    () => ({
      newsArray: data,
      newsLoading: isLoading,
      newsError: error,
      newsValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;

}// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function GetRosters(isReady, isLeague, team, player='', season='', division='', sortby='', playerYN='') {
  let URL = null;
  if (isReady){
    if (isLeague){
      URL = (team) ? [endpoints.rosters.list, { params: { team, player, season, division, sortby, playerYN } }] : null;
      // console.log('team && season && division in GetRosters', team, season, division, URL);
    } else {
      URL = (team) ? [endpoints.rosters.list, { params: { team, player, season, sortby, playerYN } }] : null;
      // console.log('team && season in GetRosters', team, season, division, URL);
    }
  }

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      rosterArray: data,
      rosterLoading: isLoading,
      Error: error,
      rosterValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function GetSchedules(isReady, isLeague, team, game='', season='', division='', teamName='') {
  let URL = null;
  if (isReady){
    if (isLeague){
      URL = (team && (season || game)) ? [endpoints.schedules.list, { params: { team, game, season, division, teamName} }] : null;
      // console.log('team && season && division in GetSchedules', team, game, season, division, teamName, URL);
    } else {
      URL = (team && (season || game)) ? [endpoints.schedules.list, { params: { team, game, season } }] : null;
      // console.log('team && season in GetSchedules', team, game, season, URL);
    }
  }
  
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      dataArray: data,
      Loading: isLoading,
      Error: error,
      Validating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function GetCalendars(team, season='', date='', teamName='') {
  const URL = team ? [endpoints.calendars.list, { params: { team, season, date, teamName } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(() => {
    const events = data?.map((event) => ({
      ...event,
      textColor: event.color,
    }));

    return {
      events: events || [],
      eventsLoading: isLoading,
      eventsError: error,
      eventsValidating: isValidating,
      eventsEmpty: !isLoading && !data?.length,
    };
  }, [data, error, isLoading, isValidating]);

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function GetStats(isReady, isLeague, team, game='', player='', seasons='', division='', teamName='') {
  let URL = null;
  if (isReady){
    if (isLeague){
      URL = (team) ? [endpoints.stats.list, { params: { team, game, player, seasons, division, teamName} }] : null;
      // console.log('team && season && division in GetSchedules', team, game, player, season, division, teamName, URL);
    } else {
      URL = (team) ? [endpoints.stats.list, { params: { team, game, player, seasons } }] : null;
      // console.log('team && season in GetStats', team, game, player, seasons, URL);
    }
  }
  
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      dataArray: data,
      Loading: isLoading,
      Error: error,
      Validating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function GetStandings(isReady, team, season='', division='') {
  const URL = isReady && team ? [endpoints.standings.list, { params: { team, season, division } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      StandingsArray: data,
      StandingsLoading: isLoading,
      StandingsError: error,
      StandingsValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function GetPhotos(team, album='',  limit=0) {
  const URL = team ? [endpoints.photos.list, { params: { team, album, limit } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  // console.log('GetPhotos', data)

  const memoizedValue = useMemo(
    () => ({
      photoArray: data,
      photoLoading: isLoading,
      photoError: error,
      photoValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function GetMember(team, member) { // DON'T USE THIS WHEN DATA CHANGIND
  const URL = team ? [endpoints.members.member, { params: { team, member } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {refreshWhenHidden: true});

  const memoizedValue = useMemo(
    () => ({
      memberArray: data,
      memberLoading: isLoading,
      memberError: error,
      memberValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  mutate(URL);

  return memoizedValue;
}

// ----------------------------------------------------------------------
export function GetVideos(team) {
  const URL = team ? [endpoints.videos.list, { params: { team } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      dataArray: data,
      Loading: isLoading,
      Error: error,
      Validating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function GetLinks(team) {
  const URL = team ? [endpoints.links.list, { params: { team } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      dataArray: data,
      Loading: isLoading,
      Error: error,
      Validating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function GetContacts(team) {
  const URL = team ? [endpoints.contacts.list, { params: { team } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      dataArray: data,
      Loading: isLoading,
      Error: error,
      Validating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------
export function GetFields(team) {
  // console.log('endpoints.fields.list',endpoints.fields.list);
  const URL = team ? [endpoints.fields.list, { params: { team } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      dataArray: data,
      Loading: isLoading,
      Error: error,
      Validating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------
export function GetHandouts(team) {
  const URL = team ? [endpoints.handouts.list, { params: { team } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      handoutsArray: data,
      handoutsLoading: isLoading,
      handoutsError: error,
      handoutsValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------
export function GetUpdates(isReady, team, member='', from=0, limit=0, count=0) {
  const URL = (isReady && team) ? [endpoints.updates.list, { params: { team, member, from, limit, count } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      updatesArray: data,
      updatesLoading: isLoading,
      updatesError: error,
      updatesValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function GetSponsors(team) {
  const URL = team ? [endpoints.sponsors.list, { params: { team } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      sponsorsArray: data,
      sponsorsLoading: isLoading,
      sponsorsError: error,
      sponsorsValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function GetWidgets(team) {
  const URL = team ? [endpoints.widgets.list, { params: { team } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  // console.log('GetWidgets', data)

  const memoizedValue = useMemo(
    () => ({
      widgetsArray: data,
      widgetsLoading: isLoading,
      widgetsError: error,
      widgetsValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function GetRegistrations(team) {
  const URL = team ? [endpoints.registrations.list, { params: { team } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      dataArray: data,
      Loading: isLoading,
      Error: error,
      Validating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function GetCustoms(team, id='') {
  const URL = team ? [endpoints.customs.list, { params: { team, id } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      dataArray: data,
      Loading: isLoading,
      Error: error,
      Validating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function GetDivisions(isReady, isLeague, team, season) {
  // console.log('isReady, isLeague in GetDivisions', isReady, isLeague);
  const URL = (isReady && isLeague && team !== '' && season !== '') ? 
        [endpoints.divisions.list, { params: { team, season } }] 
        : 
        null;
  // console.log('team && season in GetDivisions', team, season, URL);

  // console.log('************GETTING DIVISIONS', URL);
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  // console.log('DIVISIONS: data, isLoading, error, isValidating', data, isLoading, error, isValidating);

  const memoizedValue = useMemo(
    () => ({
      divisionsArray: data,
      divisionsLoading: isLoading,
      divisionsError: error,
      divisionsValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function GetButtons(ready, team) {
  const URL = (ready && team) ? [endpoints.buttons.list, { params: { team } }] : null;
  // console.log('************GETTING BUTTONS', URL);
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  // console.log('data, isLoading, error, isValidating', data, isLoading, error, isValidating);

  const memoizedValue = useMemo(
    () => ({
      buttonArray: data,
      buttonLoading: isLoading,
      buttonError: error,
      buttonValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function GetEmails(ready, team, member='', id=0, from=0, limit=10, count=0) {
  const URL = (ready && team) ? [endpoints.emails.list, { params: { team, member, id, from, limit, count } }] : null;
  // console.log('************GETTING EMAILS', URL);
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  // console.log('data, isLoading, error, isValidating', data, isLoading, error, isValidating);

  const memoizedValue = useMemo(
    () => ({
      emailsArray: data,
      emailsLoading: isLoading,
      emailsError: error,
      emailsValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function GetComments(ready, team, member='', page='', page_id='', from=0, limit=12, timeafter='', timebefore='', thread='', timestamp = '', count=0) {
  const URL = (ready && team) ? [endpoints.messages.list, { params: { team, member, page, page_id, from, limit, timeafter, timebefore, thread, timestamp, count } }] : null;
  // console.log('************GETTING COMMENTS', URL);
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  // console.log('data after GetComments', data);

  const memoizedValue = useMemo(
    () => ({
      commentsArray: data,
      commentsLoading: isLoading,
      commentsError: error,
      commentsValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function GetUnread(ready, team, member, type='', count=0) {
  const URL = (ready && team) ? [endpoints.unread.list, { params: { team, member, type, count } }] : null;
  // console.log('************GETTING COMMENTS', URL);
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  // console.log('data after GetUnread', data);

  const memoizedValue = useMemo(
    () => ({
      unreadArray: data,
      unreadLoading: isLoading,
      unreadError: error,
      unreadValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function GetChats(ready, team, member, count=0) {
  const URL = (ready && team) ? [endpoints.chats.list, { params: { team, member, count } }] : null;
  // console.log('************GETTING CHATS', URL);
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  // console.log('data after GetChats', data);

  const memoizedValue = useMemo(() => {
    const byId = keyBy(data?.conversations, 'id') || {};
    const allIds = Object.keys(byId) || [];

    return {
      conversations: {
        byId,
        allIds,
      },
      conversationArray: data?.conversations,
      conversationsLoading: isLoading,
      conversationsError: error,
      conversationsValidating: isValidating,
      conversationsEmpty: !isLoading && !allIds.length,
    };
  }, [data?.conversations, error, isLoading, isValidating]);

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function GetChat(ready, team, member, id) {
  const URL = (ready && team) ? [endpoints.chats.list, { params: { team, member, id } }] : null;
  // console.log('************GETTING CHATS', URL);
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  // console.log('data after GetChats', data);

  const memoizedValue = useMemo(
    () => ({
      conversation: data?.conversation,
      conversationLoading: isLoading,
      conversationError: error,
      conversationValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export async function sendMessage(conversationId, messageData, team, member, count=0) { 
  const CONVERSATIONS_URL = [endpoints.chats.list, { params: { team, member, count } }];

  // console.log('conversationId, messageData, team, member, files', conversationId, messageData, team, member);
  const CONVERSATION_URL = [
    endpoints.chats.list,
    {
      params: { team, member, id: conversationId },
    },
  ];

  /**
   * Work on server
   */
  const data = { team, member, id: conversationId, messageData };
  await axiosInstance.post(endpoints.chats.save, data);

  /**
   * Work in local
   */
  mutate(
    CONVERSATION_URL,
    (currentData) => {
      // console.log('currentData', currentData);

      const { conversation: currentConversation } = currentData;

      const conversation = {
        ...currentConversation,
        messages: [...currentConversation.messages, messageData],
      };

      return {
        conversation,
      };
    },
    false
  );

  /**
   * Work in local
   */
  mutate(
    CONVERSATIONS_URL,
    (currentData) => {
      const { conversations: currentConversations } = currentData;

      const conversations = currentConversations.map((conversation) =>
        conversation.id === conversationId
          ? {
              ...conversation,
              messages: [...conversation.messages, messageData],
            }
          : conversation
      );

      return {
        conversations,
      };
    },
    false
  );
}

// ----------------------------------------------------------------------

export async function createConversation(conversationData, team, member, count=0) {
  const URL = [endpoints.chats.list, { params: { team, member, count } }];

  /**
   * Work on server
   */
  const data = { conversationData, team, member };

  // console.log('data in createConversation', data);

  const res = await axiosInstance.post(endpoints.chats.save, data);

  conversationData.id = res.data.conversation.id;

  /**
   * Work in local
   */
  mutate(
    URL,
    (currentData) => {
      const conversations = [...currentData.conversations, conversationData];
      return {
        ...currentData,
        conversations,
      };
    },
    false
  );

  return res.data;
}

// ----------------------------------------------------------------------

export async function clickConversation(conversationId, team, member, count=0) {
  const CONVERSATIONS_URL = [endpoints.chats.list, { params: { team, member, count } }];
  const URL = endpoints.chats.read;

  /**
   * Work on server
   */

  // const res = await axiosInstance.post(URL, { id: conversationId, team, member });
  await axiosInstance.post(URL, { id: conversationId, team, member });
  // console.log('res in clickConversation', res);

  /**
   * Work in local
   */
  mutate(
    CONVERSATIONS_URL,
    (currentData) => {
      // console.log('currentData in clickConversation', currentData);
      const conversations = currentData.conversations.map((conversation) =>
        conversation.id === conversationId ? { ...conversation, unreadCount: 0 } : conversation
      );

      return {
        ...currentData,
        conversations,
      };
    },
    false
  );
}

// ----------------------------------------------------------------------
export function GetMembers(ready, team, member='', ignoreSelf=0, confirmed=-1, ignore_registrants=1, count=0) {
  // console.log('trying to get members', ready, team);
  const URL = (ready && team) ? [endpoints.members.list, { params: { team, member, ignoreSelf, confirmed, ignore_registrants, count } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {refreshWhenHidden: true});
  // console.log('MEMBERS, data, isLoading, error, isValidating', data, isLoading, error, isValidating);

  const memoizedValue = useMemo(
    () => ({
      contacts: data?.contacts || [],
      contactsLoading: isLoading,
      contactsError: error,
      contactsValidating: isValidating,

    }),
    [data, error, isLoading, isValidating]
  );

  // mutate(URL);

  return memoizedValue;
}

// ----------------------------------------------------------------------

export async function clickNotification(notification, team, member) {
  const NOTIFICATIONS_URL = [endpoints.messages.list, { params: { team, member } }];
  const URL = endpoints.unread.save;

  const typewithS = `${notification.type}s`;

  /**
   * Work on server
   */

  // const res = await axiosInstance.post(URL, { type: typewithS, team, member });
  await axiosInstance.post(URL, { type: typewithS, team, member });
  // console.log('res in clickConversation', res);

  /**
   * Work in local
   */
  mutate(
    NOTIFICATIONS_URL,
    (currentData) => {
      console.log('currentData in clickNotification', currentData);
      // const conversations = currentData.conversations.map((conversation) =>
      //  conversation.id === conversationId ? { ...conversation, unreadCount: 0 } : conversation
      // );

      return {
        ...currentData,
        // conversations,
      };
    },
    false
  );
}
